<template>
    <div class="axil-signin-area">
        <!-- Start Header -->
        <div class="signin-header">
            <div class="row align-items-center">
                <div class="col-md-6">
                    <router-link to="/" class="site-logo"
                        ><img
                            src="/logo.png"
                            alt="logo"
                            style="max-height: 100px"
                    /></router-link>
                </div>
                <div class="col-md-6">
                    <div class="singin-header-btn">
                        <p>{{ $t("Van már fiókod?") }}</p>
                        <router-link
                            to="/login"
                            class="axil-btn btn-bg-secondary sign-up-btn"
                            >{{ $t("Jelentkezz be") }}</router-link
                        >
                    </div>
                </div>
            </div>
        </div>
        <!-- End Header -->

        <div class="row">
            <div class="col-xl-4 col-lg-6">
                <div
                    class="axil-signin-banner bg_image bg_image--10"
                    style="background: url('/ragavan.png'); background-repeat: no-repeat; background-size: cover;"
                >
                    <h3 class="title" style="color: white">
                        {{
                            $t(
                                "Magic Singles - Magyarország legnagyobb Online Mtg Single Webshopja"
                            )
                        }}
                    </h3>
                </div>
            </div>
            <div class="col-lg-6 offset-xl-2">
                <div class="axil-signin-form-wrap">
                    <div class="axil-signin-form">
                        <h3 class="title">{{ $t("Jelszó módosítása") }}</h3>
                        <p class="b2 mb--55">
                            {{ $t("Add meg az új jelszavadat") }}
                        </p>
                        <form class="singin-form" v-on:submit.prevent="doReset">
                            <div class="form-group">
                                <label>{{ $t("Jelszó") }}</label>
                                <input
                                    type="password"
                                    class="form-control"
                                    name="password"
                                    v-model="model.password"
                                    value="123456789"
                                />
                            </div>
                            <div class="form-group">
                                <label>{{ $t("Jelszó megerősítése") }}</label>
                                <input
                                    type="password"
                                    class="form-control"
                                    name="password"
                                    v-model="model.password_confirmation"
                                    value="123456789"
                                />
                            </div>
                            <div class="form-group">
                                <button
                                    type="submit"
                                    class="axil-btn btn-bg-primary submit-btn"
                                >
                                    {{ $t("Jelszó módosítása") }}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
    name: "ResetPass",
    data() {
        return {
            model: {
                password: null,
                password_confirmation: null
            }
        };
    },
    mounted() {
        this.onReset();
    },
    watch: {
        "$route.query": {
            handler: function() {
                this.onReset();
            },
            deep: true
        }
    },
    methods: {
        onReset() {
            this.model.token = this.$route.params.token;
            this.model.email = this.$route.query.email.replace(" ", "+");
        },
        doReset() {
            const self = this;
            ApiService.post(`/reset-password`, this.model).then(() => {
                this.model = {
                    password: null,
                    password_confirmation: null
                };

                self.$router.push({ name: "Login" });
            });
        }
    }
};
</script>

<style scoped></style>
